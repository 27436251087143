<template >
  <div id="Home">
    <HeaderMenu />
    <div class="home-cont">
      <slideHome />
      <!--seccion 1-->
      <div class="sec-1"
        :style="{
          'background-image': 'url(' + isWebp(require('../assets/paca.webp'), require('../assets/paca.png')) + ')',
        }">
        <div class="sec-1-cont">
          <p
            class="titulo"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            :data-aos-delay="50"
          >
            LO MÁS VENDIDO
          </p>
          <div v-if="lomasvendido.length == 0">
            <p class="no-found">NO SE ENCONTRARON PRODUCTOS</p>
          </div>
          <div v-else>
            <div class="most-sale-cont pc">
              <div
                class="product-cont"
                v-for="(pro, i) in lomasvendido"
                :key="i"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                :data-aos-delay="150"
              >
                <a :href="'/producto/' + pro.url">
                  <div class="product-image">
                    <img
                      :src="
                        urlpath.url() +
                        '/P6H_3Pm/bt7-EO4/get-product-image/' +
                        isWebp(regexImg(pro.image),pro.image)
                      "
                      alt="product"
                    />
                  </div>
                  <p class="product-name">{{ pro.name }}</p>
                  <p class="price">${{ pro.price }} MXN</p>
                </a>
                <a class="btn-comprar" :href="'/producto/' + pro.url">
                  <img
                    src="../assets/carrito-i-w.svg"
                    alt="carrito"
                    class="carrito"
                  />
                  <p>COMPRAR</p>
                </a>
              </div>
            </div>
            <div class="most-sale-cont mo">
              <carousel :perPage="1" navigationEnabled>
                <slide
                  class="slide-item"
                  v-for="(pro, i) in lomasvendido"
                  :key="i"
                >
                  <div class="product-cont">
                    <a :href="'/producto/' + pro.url">
                      <div class="product-image">
                        <img
                          :src="
                            urlpath.url() +
                            '/P6H_3Pm/bt7-EO4/get-product-image/' +
                            isWebp(regexImg(pro.image),pro.image)
                          "
                          alt="product"
                        />
                      </div>
                      <p class="product-name">{{ pro.name }}</p>
                      <p class="price">${{ pro.price }}MXN</p>
                    </a>
                    <a class="btn-comprar" :href="'/producto/' + pro.url">
                      <img
                        src="../assets/carrito-i-w.svg"
                        alt="carrito"
                        class="carrito"
                      />
                      <p>COMPRAR</p>
                    </a>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
      <!--sec 2-->
      <div class="sec-2"
        :style="{
          'background-image': 'url(' + isWebp(require('../assets/chivo1.webp'), require('../assets/chivo1.png')) + ')',
        }">
        <div class="sec-2-cont"> 
          <p
            class="titulo-1"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="50"
          >
            ENCUENTRA TODO LO QUE NECESITAS PARA TUS MASCOTAS, GANADO, Y CRÍA DE
            ANIMALES.
          </p>
          <img
            :src="isWebp(require('../assets/goat1.webp'), require('../assets/goat1.png'))"
            alt="men-goat"
            class="chivito pc"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="150"
          />
          <div
            class="texto-cont"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="300"
          >
            <p
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="300"
            >
              Encuentra todo lo que necesitas para tus mascotas, ganado y cría
              de animales. En Agriga contamos con un amplio surtido de productos
              y marcas reconocidas de alimento, medicamento y accesorios para
              todo tipo de especies: Perros, Gatos, Roedores, Caballos, Ganado
              Bovino y Porcino. 
            </p>
            <p
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="450"
            >
              Somos la mejor opción en nutrición animal. Déjanos ayudarte a
              estimular y cuidar su máximo desarrollo a precios razonables. 
            </p>
          </div>
        </div>
      </div>
      <!--sec 3-->
      <div class="sec-3">
        <p
          class="titulo-s3"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="700"
          data-aos-delay="100"
        >
          CATEGORÍAS
        </p>
        <div class="sec-3-cont">
          <a
            href="/tienda/bovinos"
            class="mRight"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            <div class="cat-item">
              <img src="../assets/cat1.svg" alt="cat" class="cat-img uno" />
              <p>BOVINOS</p>
            </div>
          </a>
          <a
            href="/tienda/equinos"
            class="mRight"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            <div class="cat-item">
              <img src="../assets/cat2.svg" alt="cat" class="cat-img" />
              <p>EQUINOS</p>
            </div>
          </a>
          <a
            href="/tienda/mascotas"
            class="mRight"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="300"
          >
            <div class="cat-item">
              <img src="../assets/cat3.svg" alt="cat" class="cat-img" />
              <p>MASCOTAS</p>
            </div>
          </a>
          <a
            href="/tienda/aves"
            class="mRight"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="400"
          >
            <div class="cat-item">
              <img src="../assets/cat4.svg" alt="cat" class="cat-img" />
              <p>AVES</p>
            </div>
          </a>
          <a
            href="/tienda/agricola"
            class="mRight"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="500"
          >
            <div class="cat-item">
              <img src="../assets/cat5.svg" alt="cat" class="cat-img uno" />
              <p>AGRÍCOLA</p>
            </div>
          </a>
          <a
            href="/tienda/ferreteria"
            class="mRight"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="600"
          >
            <div class="cat-item">
              <img src="../assets/cat6.svg" alt="cat" class="cat-img tres" />
              <p>FERRETERÍA</p>
            </div>
          </a>
        </div>
      </div>
      <img :src="isWebp(require('../assets/perrito-caballo.webp'), require('../assets/perrito-caballo.png'))" alt="gato" class="item-1" />
      <!--sec 4-->
      <div class="sec-4">
        <div class="sec-4-cont">
          <img
            :src="isWebp(require('../assets/gato2.webp'), require('../assets/gato2.png'))"
            alt="gato"
            class="gato"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="100"
          />
          <div class="subs-info">
            <p
              class="text"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              SUSCRIBETE PARA RECIBIR NUESTRAS NOVEDADES y PROMOCIONES
              EXCLUSIVAS PARA TI
            </p>
            <form @submit.prevent="suscribirse(email)">
              <div
                class="sub-text-form"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                data-aos-delay="300"
              >
                <label for="sub"></label>
                <input
                  type="email"
                  name="sub"
                  id="sub-text"
                  class="sub-text"
                  placeholder="Escribe tu email aquí"
                  v-model="email"
                  required
                />
                <button type="submit" class="sub-btn">SUSCRIBIR</button>
              </div>
            </form>
            <div v-if="status == 'success'" class="msg msg_success">
              <p class="">Suscripción exitosa!</p>
            </div>
            <div v-if="status == 'error'" class="msg msg_error">
              <p class="">{{ message }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--sec 5-->
      <div class="sec-5">
        <img :src="isWebp(require('../assets/item1.webp'), require('../assets/item1.png'))" alt="item-2" class="item-2 pc" />
        <img :src="isWebp(require('../assets/item1m.webp'), require('../assets/item1m.png'))" alt="item-2" class="item-2 mo" />
        <div class="sec-5-cont">
          <div class="s5-info">
            <img
              :src="isWebp(require('../assets/p1.webp'), require('../assets/p1.png'))"
              alt="s5i"
              class="s5-img"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="100"
            />
            <div
              class="text"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <p class="titulo">SERVICIO</p>
              <p class="desc">
                Enfocados en atender a los clientes para su satisfaccion.
              </p>
            </div>
          </div>
          <div class="s5-info">
            <img
              :src="isWebp(require('../assets/p2.webp'), require('../assets/p2.png'))"
              alt="s5i"
              class="s5-img"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="100"
            />
            <div
              class="text"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <p class="titulo">CALIDAD/PRECIO</p>
              <p class="desc">
                Para ayudar al ganadero local, contamos con los precios más
                accesibles sin descuidar la calidad en ningún momento.
              </p>
            </div>
          </div>
          <div class="s5-info">
            <img
              :src="isWebp(require('../assets/p3.webp'), require('../assets/p3.png'))"
              alt="s5i"
              class="s5-img"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="100"
            />
            <div
              class="text tre"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <p class="titulo">ENVIOS 100% SEGUROS</p>
              <p class="desc">
                Recibe tus productos favoritos estés donde estés. En Agriga
                contamos con servicio de envíos locales y nacionales.
              </p>
            </div>
          </div>
          <div class="s5-info">
            <img
              :src="isWebp(require('../assets/p4.webp'), require('../assets/p4.png'))"
              alt="s5i"
              class="s5-img quattro"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="100"
            />
            <div
              class="text"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <p class="titulo">ASESORÍA</p>
              <p class="desc">
                Recibe tus productos favoritos estés donde estés. En Agriga
                contamos con servicio de envíos locales y nacionales. <br>
                Comunícate con nosotros para recibir asesoría sobre nuestros poroductos u otras áreas del ramo agropecuario.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--sec 6-->
      <div class="sec-6">
        <div class="sec-6-cont">
          <p
            class="titulo"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            NUESTRAS MARCAS
          </p>

          <div class="brands slideBrand pc">
            <div
              class="brand-cont"
              v-for="(br, i) in brands"
              :key="i"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              :data-aos-delay="50 * i"
            >
              <a href="">
                <img :src="isWebp(br.imagew, br.image)" alt="logo" class="brand-logo" />
              </a>
            </div>
          </div>

          <carousel :perPage="1" navigationEnabled class="slideBrand mo">
            <slide class="slide-item">
              <div class="brands">
                <div v-for="(br, i) in brands" :key="i" class="flexBrand">
                  <div class="brand-cont" v-if="br.columna == 1">
                    <a href="">
                      <img :src="isWebp(br.imagew, br.image)" alt="logo" class="brand-logo" />
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide class="slide-item">
              <div class="brands">
                <div v-for="(br, i) in brands" :key="i" class="flexBrand">
                  <div class="brand-cont" v-if="br.columna == 2">
                    <a href="">
                      <img :src="isWebp(br.imagew, br.image)" alt="logo" class="brand-logo" />
                    </a>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
      <!--sec 7-->
      <div class="sec-7">
        <div class="sec-7-cont">
          <p
            class="titulo"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            BLOG
          </p>
          <p
            class="subtitulo"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            ÚLTIMAS ENTRADAS
          </p>
          <div class="blog-items-cont pc">
            <div
              class="blog-item"
              v-for="(bl, i) in blogdata"
              :key="i"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              :data-aos-delay="200 + (i + 6) * 50"
            >
              <a :href="'/blog/' + bl.url">
                <div
                  :style="{ 'background-image': 'url(' + srcImg(bl.image) + ')' }"
                  alt="blog"
                  class="blog-img divblog-img"
                />
                <div class="blog-info-cont">
                  <div class="info-cont">
                    <p class="titulo-bl">{{ bl.title }}</p>
                    <p class="author-bl">Por {{ bl.autor_name+' '+bl.autor_lastname }}</p>
                    <p class="descrp-bl">{{ bl.descrp }}</p>
                    <p class="fecha-coments-bl">
                      {{ bl.dateblog }} <!--{{ bl.comentarios }} comentario--> 
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="blog-items-cont mo">
            <carousel :perPage="1">
              <slide class="blog-item" v-for="(bl, i) in blogdata" :key="i">
                <a :href="'/blog/' + bl.url">
                  <img :src="srcImg(bl.image)" alt="blog" class="blog-img" />
                  <div class="blog-info-cont">
                    <div class="info-cont">
                      <p class="titulo-bl">{{ bl.title }}</p>
                      <p class="author-bl">Por{{ bl.autor_name+' '+bl.autor_lastname }}</p>
                      <p class="descrp-bl">{{ bl.descrp }}</p>
                      <p class="fecha-coments-bl">
                         {{ bl.dateblog }}<!--{{ bl.comentarios }} comentario--> 
                      </p>
                    </div>
                  </div>
                </a>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <!--contacto-->
    </div>
    <Footer />
  </div>
</template>
<script>
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import slideHome from "../components/elements/slideHome.vue";
import Footer from "../components/fixedItems/Footer.vue";
import { Carousel, Slide } from "vue-carousel";
import dayjs from "dayjs";
import { mapActions } from "vuex";
let urlpath = require("../global/url");
export default {
  name: "Home",
  components: {
    HeaderMenu,
    slideHome,
    Footer,
    Carousel,
    Slide,
  },
  data() {
    return {
      urlpath,
      email: "", //no borrar esta variable si se usa.
      status: "",
      message: "",

      products: [
        {
          image: require("../assets/saco1.png"),
          titulo: "SMART BITES ADULTO 1KG",
          precio: 600,
        },
        {
          image: require("../assets/saco2.png"),
          titulo: "SMART BITES ADULTO 1KG",
          precio: 600,
        },
        {
          image: require("../assets/saco3.png"),
          titulo: "SMART BITES ADULTO 1KG",
          precio: 600,
        },
      ],

      brands: [
        {
          link: "",
          image: require("../assets/b1.png"),
          imagew: require("../assets/b1.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b2.png"),
          imagew: require("../assets/b2.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b3.png"),
          imagew: require("../assets/b3.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b4.png"),
          imagew: require("../assets/b4.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b5.png"),
          imagew: require("../assets/b5.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b6.png"),
          imagew: require("../assets/b6.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b7.png"),
          imagew: require("../assets/b7.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b8.png"),
          imagew: require("../assets/b8.webp"),
          columna: 1,
        },
        {
          link: "",
          image: require("../assets/b9.png"),
          imagew: require("../assets/b9.webp"),
          columna: 2,
        },
        {
          link: "",
          image: require("../assets/b10.png"),
          imagew: require("../assets/b10.webp"),
          columna: 2,
        },
        {
          link: "",
          image: require("../assets/b11.png"),
          imagew: require("../assets/b11.webp"),
          columna: 2,
        },
        {
          image: require("../assets/b12.png"),
          imagew: require("../assets/b12.webp"),
          columna: 2,
        },
        {
          link: "",
          image: require("../assets/b13.png"),
          imagew: require("../assets/b13.webp"),
          columna: 2,
        },
        {
          link: "",
          image: require("../assets/b14.png"),
          imagew: require("../assets/b14.webp"),
          columna: 2,
        },
        {
          link: "",
          image: require("../assets/b15.png"),
          imagew: require("../assets/b15.webp"),
          columna: 2,
        },
      ],
      blog: [
        {
          image: require("../assets/bl1.png"),
          imagew: require("../assets/bl1.webp"),
          titulo: "Parvovirosis canina: diagnóstico y tratamiento.",
          autor: "Staff Agriga",
          descripcion:
            "Recibe tus productos favoritos  estés donde estés. En Agriga contamos con servicio de envíos locales y nacionales.",
          fecha: "14/04/2021",
          comentarios: 1,
        },
        {
          image: require("../assets/bl2.png"),
          imagew: require("../assets/bl2.webp"),
          titulo: "Parvovirosis canina: diagnóstico y tratamiento.",
          autor: "Staff Agriga",
          descripcion:
            "Recibe tus productos favoritos  estés donde estés. En Agriga contamos con servicio de envíos locales y nacionales.",
          fecha: "14/04/2021",
          comentarios: 2,
        },
        {
          image: require("../assets/bl3.png"),
          imagew: require("../assets/bl3.webp"),
          titulo: "Parvovirosis canina: diagnóstico y tratamiento.",
          autor: "Staff Agriga",
          descripcion:
            "Recibe tus productos favoritos  estés donde estés. En Agriga contamos con servicio de envíos locales y nacionales.",
          fecha: "14/04/2021",
          comentarios: 1,
        },
      ],
    };
  },

  metaInfo() {
    return {
      title: "Bienvenidos",
      titleTemplate: "%s | Agriga de México",
      htmlAttrs: {
        lang: "es",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Encuentra todo lo que necesitas para tus mascotas, ganado y cría de animales.",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  async created() {
    await this.getAllInfoPro3("product");
    await this.getAllInfoBlo("blog");
  },
  computed: {
    lomasvendido() {
      let array = this.$store.getters["product/data3"];
      let filtered = array.filter(
        ({ lomasvendido }) => lomasvendido !== "No"
      ); /*
      console.log(filtered)*/
      let currentIndex = filtered.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [filtered[currentIndex], filtered[randomIndex]] = [
          filtered[randomIndex],
          filtered[currentIndex],
        ];
      }
      let finaldata = [];
      for (let k = 0; k < filtered.length; k++) {
        if (filtered[k].status == "ACTIVO") {
          finaldata.push(filtered[k]);
        }
      }

      return finaldata.slice(0, 3);
    },
    blogdata() {
      let array = this.$store.getters["blog/data"]; 
      if(array == 'error'){
        return 'error'
      }else{
          return array.slice(0, 3);
      }
    },
  },
  methods: {
    ...mapActions("product", ["getAllInfoPro3"]),
    ...mapActions("blog", ["getAllInfoBlo"]),

    /*testMail: async function () {
      this.status = "";
      this.message = ""; 
      console.log('dsd')
      let result = await this.$store.dispatch("main/addItem", {
        option: "testcorreo",
        item: '',
      });
      console.log(result)
      if (result.status == "error") {
        this.status = "error";
        this.message = result.message; 
        console.log('ERROR')
      } else {
        // success
        this.status = "success";
        this.message = "success";
        console.log('HAMSTER')
      }
    },*/
    suscribirse: async function (email) {
      this.status = "";
      this.message = "";
      let sub_date = dayjs().format("YYYY-MM-DD");
      let data = {
        email,
        sub_date,
      };

      let result = await this.$store.dispatch("suscripciones/addItemSbs", {
        option: "suscripciones",
        item: data,
      });

      if (result.status == "error") {
        this.status = "error";
        this.message = result.message;
        this.delStatus();
      } else {
        // success
        this.status = "success";
        this.message = "Suscripción exitosa!";
        this.delStatus();
      }
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 3000);
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
    },

    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    srcImg: function (img) {
      let src = `${urlpath.url()}/P6H_3Pm/bt7-EO4/blog-img/${this.isWebp(this.regexImg(img),img)}`; 
      return src;
    }, 
  },
};
</script>
<style scoped>
a {
  text-decoration-color: transparent;
}

p {
  font-family: "Helvetica World Regular";
}
.sec-1 {
  /*background-image: url("../assets/paca.png");*/
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #e3f1e5;
}
.sec-1-cont {
  width: 67.604vw;
  margin: auto;
  padding: 6.354vw 0 16.563vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.most-sale-cont {
  display: flex;
  /*width: 100%;*/
  width: 67vw;
  /*justify-content: space-between;*/
}
.most-sale-cont.pc {
  display: flex;
}
.most-sale-cont.mo {
  display: none;
}
.sec-1 .titulo,
.sec-6 .titulo,
.sec-7 .titulo {
  font-family: "Intro Rust";
  font-weight: 400;
  font-size: 1.823vw;
  line-height: 2.188vw;
  color: #fe9128;
  margin: 0 0 5vw;
}
.product-image {
  width: 21.563vw;
  height: 28.854vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}
.product-image img {
  width: 13.385vw;
  height: auto;
  transition: 0.5s;
}
.product-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
  margin-right: 1.406vw;
}
.product-cont:hover .product-image img {
  transform: scale(1.09);
}
.product-name {
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 129.5%;
  margin: 2.292vw 0 0;
  color: #929292;
  transition: 0.5s;
  cursor: default;
  text-transform: uppercase;
  text-align: center;
  width: 21.5vw;
}
.product-cont:hover .product-name {
  color: #616161;
}
.price {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 129.5%;
  text-align: center;
  color: #fe9128;
  margin: 0.6vw 0 1.4vw;
  cursor: default;
}
.btn-comprar p {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.521vw;
  line-height: 129.5%;
  text-align: center;
  color: #ffffff;
  margin: 0 0 0 0.4vw;
}
.btn-comprar {
  width: 7.813vw;
  height: 2.552vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #3ebdb1;
  color: white;
  cursor: pointer;
  transition: 0.5s;
}
.btn-comprar:hover {
  background: #2d8e84;
}
.btn-comprar .carrito {
  width: 0.781vw;
  height: auto;
}

.sec-2 {
  /* background-image: url("../assets/goat1.png");
  background-size: 38.594vw;
  background-repeat: no-repeat;
  background-position-x: 19.844vw;
  background-position-y: bottom;*/
}
.sec-2-cont {
  display: flex;
  /* justify-content: space-between; */
  width: 76.7vw;
  padding: 5.469vw 0 0;
  margin: auto;
}
.chivito.pc {
  width: 25.729vw;
  height: auto;
  position: relative;
  right: 1.458vw;
  padding-right: 4vw;
}
.sec-2 .titulo-1 {
  margin: 0;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 2.188vw;
  color: #3ebdb1;
  width: 17.24vw;
  padding-top: 5.521vw;
}
.texto-cont {
  width: 29.375vw;
}
.texto-cont p {
  font-family: "Helvetica World Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 194.5%;
  color: #929292;
  margin: 0;
}
.texto-cont p:first-child {
  padding-top: 5.521vw;
  padding-bottom: 2.604vw;
}
.sec-3 {
  background-size: 9.85vw;
  background-repeat: no-repeat;
  background-color: #fe9128;
  background-image: url("../assets/categorias-text.svg");
}
.sec-3-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 60.313vw;
  height: 39.375vw;
  padding: 16.198vw 0 15.469vw 25.417vw;
}
.titulo-s3 {
  display: none;
}
img.cat-img {
  width: auto;
  height: 8.073vw;
}
img.cat-img.uno {
  width: auto;
  height: 6.667vw;
}
img.cat-img.tres {
  width: auto;
  height: 7.448vw;
}
.cat-item p {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.302vw;
  line-height: 1.563vw;
  color: #ffffff;
  margin: 5vw 0 0;
}
.mRight {
  margin-right: 14.896vw;
}
.mRight:nth-child(3),
.mRight:nth-child(6) {
  margin-right: 0;
}
.mRight:hover .cat-item {
  transform: scale(1.08);
}
.cat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
  justify-content: flex-end;
  height: 15vw;
}
.item-1 {
  width: 100%;
  height: 37.083vw;
}

.sec-4-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80.26vw;
  padding: 7.292vw 0;
  margin: auto;
}
.sec-4-cont .gato {
  width: 39.375vw;
  height: auto;
}
.subs-info .text {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.979vw;
  color: #fe9128;
  margin: 0 0 4.323vw;
  width: 30.052vw;
}
.subs-info {
  /*width: 30.052vw;*/
  width: 33.052vw;
}
.sub-text-form {
  border: none;
  box-sizing: border-box;
  border-radius: 3.125vw;
  width: auto;
  height: 4.015vw;
  overflow: hidden;
  background-color: transparent;
  transition: 0.5s;
  display: flex;
}
/*.sub-text-form:hover {
  background-color: #dfc051;
}*/
/***
    background: #dfc051; */
#sub-text {
  border-top: 0.052vw solid #929292;
  border-bottom: 0.052vw solid #929292;
  border-left: 0.052vw solid #929292;
  border-right: none;
  border-radius: 3.125vw 0 0 3.125vw;
  width: 21.635vw;
  height: auto;
  background-color: white;
  padding: 0 0 0 2vw;
}
.sub-btn {
  width: 10.1158vw;
  /*width: 8.958vw;*/
  height: auto;
  border: none;
  background: #fcdd6f;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 2.031vw;
  color: #ffffff;
  cursor: pointer;
  padding: 0;
}
.item-2 {
  width: 47.813vw;
  height: 62.188vw;
}
.item-2.pc {
  display: block;
}
.item-2.mo {
  display: none;
}
.sec-5 {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.sec-5-cont {
  padding: 5.417vw 10.104vw;
  background: #e3f1e5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.s5-info {
  display: flex;
  justify-content: space-between;
  width: 31vw;
}
.s5-info .text {
  width: 15.844vw;
}
.s5-info .text.tre {
  width: 15.844vw;
}
.s5-img {
  width: auto;
  height: 6.042vw;
}
.s5-img.quattro {
  width: auto;
  height: 4.063vw;
}
.sec-5 .titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.146vw;
  line-height: 2.031vw;
  color: #fe9128;
  margin: 0 0 1vw;
}
.desc {
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 194.5%;
  color: #929292;
  margin: 0;
}
.sec-6-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9.479vw 0;
}
.brands {
  width: 75.052vw;
  height: 35.781vw;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.slideBrand.pc {
  display: flex;
}
.slideBrand.mo {
  display: none;
}
.brand-cont {
  width: 14.2vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand-cont a:hover .brand-logo {
  transform: scale(1.09);
}
.brand-logo {
  width: 7.76vw;
  height: auto;
  transition: 0.5s;
}

.sec-7 {
  background: #f4f4f4;
}
.sec-7-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.344vw 0;
  width: 83.958vw;
  margin: auto;
}
.sec-7 .titulo {
  margin: 0 0 1.2vw !important;
}
.sec-7 .subtitulo {
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 2.031vw;
  letter-spacing: 0.17em;
  color: #929292;
  margin: 0 0 5.052vw;
}
.sec-7 .blog-items-cont {
  display: flex;
 /*justify-content: space-between;*/  
  width: 100%;
}
.sec-7 .blog-items-cont .blog-item{ 
    margin-right: 1.7vw;
}
.sec-7 .blog-items-cont .blog-item:last-of-type{ 
    margin-right: 0;
}
.sec-7 .blog-img {
  width: 27.5vw;
  height: auto;
}
.sec-7 .divblog-img {
  width: 27.5vw;
  height: 17.865vw;
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.5s;
}
.sec-7 .blog-item:hover .divblog-img {
  background-size: 115%;
}
.sec-7 .info-cont p {
  margin: 0;
}
.sec-7 .blog-info-cont {
  border: 0.05vw solid #0cee72;
  position: relative;
  bottom: 0.313vw;
  background-color: #f4f4f4;
}
.sec-7 .blog-info-cont .info-cont {
  border: 0.05vw solid #0cee72;
  padding: 3.021vw;
  background: white;
  margin: 0 auto;
  bottom: 1.667vw;
  position: relative;
  width: 18vw;
}
.sec-7 .info-cont p.titulo-bl {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.354vw;
  line-height: 1.563vw;
  color: #3ebdb1;
  margin-bottom: 0.573vw;
  transition: 0.5s;
}
.sec-7 .info-cont p.titulo-bl:hover {
  color: #33988e;
}
.sec-7 p.author-bl {
  font-weight: 700;
  font-size: 0.729vw;
  line-height: 182%;
  color: #929292;
  margin-bottom: 0.938vw;
}
.sec-7 p.descrp-bl {
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 182%;
  color: #929292;
  margin-bottom: 0.938vw;
  transition: 0.5s;
}
.sec-7 p.descrp-bl:hover {
  color: #666666;
}
.sec-7 .fecha-coments-bl {
  font-weight: 400;
  font-size: 0.625vw;
  line-height: 194.5%;
  color: #fe9128;
}
.blog-items-cont.pc {
  display: flex;
}
.blog-items-cont.mo {
  display: none;
}
p.no-found {
  font-size: 1.9vw;
  font-family: "Intro Rust";
  font-weight: 400;
  color: #93a796;
  margin: 0;
}
@media (min-width:768px) {
  
  .sec-2 {
    background-image: none!important;
  }
}
@media (max-width: 768px) {
  .sec-7 .blog-items-cont .blog-item{
    margin-right: 0;
  }
  p.no-found {
    font-size: 3vw;
  }
  .most-sale-cont.pc {
    display: none;
  }
  .most-sale-cont.mo {
    display: flex;
  }
  .sec-1-cont {
    width: 79.459vw;
    margin: auto;
    padding: 13.043vw 0 27.295vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  .sec-1 .titulo,
  .sec-6 .titulo,
  .sec-7 .titulo {
    margin: 0 0 12.077vw;
    font-size: 6.039vw;
    line-height: 7.246vw;
  }
  .product-image {
    width: 65.459vw;
    margin: auto;
    height: 87.44vw;
  }
  .most-sale-cont {
    width: fit-content;
  }
  .product-image img {
    width: 40.58vw;
  }
  .product-name {
    text-align: center;
    font-size: 3.623vw;
    margin: 8.937vw 0 0;
    width: 66vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .price {
    font-size: 3.623vw;
    margin: 2.6vw 0 6.039vw;
  }
  .btn-comprar {
    width: 36.232vw;
    height: 11.836vw;
  }
  .btn-comprar p {
    font-size: 2.415vw;
    margin: 0 0 0 3.4vw;
  }
  .btn-comprar .carrito {
    width: 3.781vw;
    height: auto;
  }
  .sec-1 .VueCarousel-pagination {
    top: 137.135vw;
  }
  .sec-1 {
    background-size: 200%;
  }

  .sec-2-cont {
    display: flex;
    justify-content: space-between; /**/
    width: 79.71vw;
    padding: 10.99vw 0 24.155vw;
    margin: auto;
    flex-direction: column;
  }
  .sec-2 .titulo-1 {
    font-size: 4.831vw;
    line-height: 6.763vw;
    width: 100%;
    padding-bottom: 115.942vw;
    text-align: center;
  }
  .texto-cont {
    width: 100%;
  }
  .texto-cont p {
    font-size: 3.865vw;
    line-height: 194.5%;
  }
  .texto-cont p:first-child {
    padding-top: 0;
    padding-bottom: 9.662vw;
  }
  .sec-2 {
    /*background-image: url("../assets/chivo1.png");*/
    background-size: 100vw;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 44.844vw;
  }

  .sec-3 {
    background-image: none;
  }
  .sec-3-cont {
    padding: 0 0 15.469vw 0;
    margin: auto;
    height: 126.188vw;
    width: 68.116vw;
  }
  .titulo-s3 {
    display: block;
    font-family: "Intro Rust";
    font-style: normal;
    font-weight: 400;
    font-size: 9.662vw;
    line-height: 11.594vw;
    margin: 0 0 21.739vw;
    padding-top: 16.908vw;
    color: #ffffff;
    text-align: center;
  }
  img.cat-img,
  img.cat-img.uno,
  img.cat-img.tres {
    width: auto;
    height: 18.357vw;
  }
  .mRight {
    margin-right: 0;
    margin-bottom: 12.077vw;
    width: 29vw;
  }
  .cat-item p {
    font-size: 3.865vw;
    line-height: 3.865vw;
  }
  .item-1 {
    width: 100%;
    height: 54.589vw;
    object-fit: cover;
  }
  .sec-4-cont {
    flex-direction: column-reverse;
  }
  .subs-info {
    width: 100%;
  }
  .subs-info .text {
    text-align: center;
    font-size: 3.865vw;
    line-height: 6.039vw;
    color: #fe9128;
    margin: 0 0 4.323vw;
    width: 100%;
    text-align: center;
  }
  .sec-4-cont .gato {
    width: 80.375vw;
  }
  .sub-text-form {
    border: none;
    box-sizing: border-box;
    border-radius: 0;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  .sub-btn {
    width: 100%;
    height: 17.15vw;
    border: none;
    background: #fcdd6f;
    font-size: 3.865vw;
    line-height: 6.039vw;
    border-radius: 12vw;
    color: #ffffff;
    cursor: pointer;
    transition: 0.5s;
  }
  /*.sub-btn:hover {
    background: #dfc051;
  }*/
  #sub-text {
    width: 98%;
    height: 17.15vw;
    border-radius: 12vw;
    border: 1px solid gray;
    background-color: white;
    margin-bottom: 2.174vw;
    padding: 0;
    text-align: center;
  }

  .sec-5 {
    flex-direction: column;
  }
  .item-2 {
    width: 100%;
    height: 96.135vw;
  }
  .item-2.pc {
    display: none;
  }
  .item-2.mo {
    display: block;
  }

  .sec-5-cont {
    padding: 14.01vw 10.104vw;
  }
  .s5-img {
    width: auto;
    height: 19.565vw;
  }
  .s5-img.s5-img.quattro {
    height: 11.353vw;
  }
  .s5-info {
    width: 100%;
    padding-bottom: 12.56vw;
  }
  .sec-5 .titulo {
    font-size: 3.382vw;
    line-height: 3.382vw;
    margin: 0 0 2vw;
  }
  .s5-info .text,
  .s5-info .text.tre {
    width: 42.686vw;
  }
  .desc {
    font-size: 2.899vw;
  }

  .brand-logo {
    width: 26.812vw;
    height: auto;
  }
  .brand-cont {
    width: 37.2vw;
    height: auto;
  }
  .brands {
    height: 111.781vw;
    margin: auto;
  }
  .slideBrand.pc {
    display: none;
  }
  .slideBrand.mo {
    display: flex;
  }
  .sec-6-cont {
    padding: 18.357vw 0;
    overflow: hidden;
  }
  .flexBrand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec-7 .subtitulo {
    font-size: 2.899vw;
    line-height: 2.899vw;
    margin: 0 0 13.285vw;
  }
  .blog-items-cont.pc {
    display: none;
  }
  .blog-items-cont.mo {
    display: flex;
  }
  .sec-7-cont {
    padding: 16.908vw 0;
    width: 83.958vw;
  }
  .sec-7 .blog-img {
    width: 88.647vw;
  }
  .sec-7 .info-cont p.titulo-bl {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 6.039vw;
  }
  .sec-7 .blog-info-cont .info-cont {
    padding: 9.179vw;
    bottom: 6.667vw;
    width: 59vw;
  }
  .sec-7 .blog-info-cont {
    width: 88.647vw;
  }
  .sec-7 p.author-bl {
    font-size: 2.899vw;
    margin-bottom: 8.454vw;
  }
  .sec-7 p.descrp-bl {
    font-size: 2.899vw;
    margin-bottom: 2.657vw;
  }
  .sec-7 .fecha-coments-bl {
    font-size: 2.899vw;
  }
  .chivito.pc {
    display: none;
  }
}
</style>