<template>
  <div class="slideHome">
    <carousel :perPage="1" navigationEnabled>
      <slide class="slide-item" v-for="(bnr, key) in banners" :key="key">
        <a :href="bnr.link">
          <img
            :src="
              urlpath.url() +
              '/P6H_3Pm/bt7-EO4/get-banner-image/' +
              isWebp(regexImg(bnr.image ), bnr.image)
            "
            alt="slide"
            class="slide-img pc"
          />
          <img
            :src="
              urlpath.url() +
              '/P6H_3Pm/bt7-EO4/get-banner-image/' +
              isWebp(regexImg(bnr.image_mv), bnr.image_mv)
            "
            alt="slide"
            class="slide-img mo"
          />
        </a>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapActions } from "vuex";
let urlpath = require("../../global/url");

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      urlpath,
    };
  },
  created() {
    this.getAllInfoBnr("banner");
  },

  computed: {
    banners() {
      return this.$store.getters["banner/data"];
    },
  },
  methods: {
    ...mapActions("banner", ["getAllInfoBnr"]),
    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
  },
};
</script>

<style>
.slideHome {
  width: 100%;
  height: 45.4vw;
  overflow: hidden;
  background: black;
}
.slide-item {
  width: 100%;
  height: 45.938vw;
}
.slide-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.slide-img.pc {
  display: block;
}
.slide-img.mo {
  display: none;
}
.VueCarousel-navigation {
  position: absolute;
  width: 100%;
  z-index: 300;
  top: 21.719vw;
}
.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  transform: none !important;
  font-size: 0;
  width: 5.521vw;
  height: 5.521vw;
  background-size: 100%;
  padding: 0;
}
.VueCarousel-navigation-prev {
  margin-left: 4.531vw;
  background-image: url("../../assets/prev.svg");
}
.VueCarousel-navigation-next {
  margin-right: 4.531vw;
  background-image: url("../../assets/next.svg");
}
.VueCarousel-pagination {
  position: absolute;
  top: 42.135vw;
}
.VueCarousel-dot-container {
  margin-top: 0 !important;
}
.VueCarousel-dot {
  margin: 0 !important;
}
.VueCarousel-dot--active {
  background-color: #0cee72 !important;
}

@media (max-width: 768px) {
  .slide-img.pc {
    display: none;
  }
  .slide-img.mo {
    display: block;
  }
  .slideHome {
    height: 129.541vw;
  }
  .slide-item {
    height: 134.541vw;
  }
  .VueCarousel-pagination {
    top: 120.135vw;
  }
  .VueCarousel-navigation-prev,
  .VueCarousel-navigation-next {
    width: 11.836vw;
    height: 11.836vw;
  }
  .VueCarousel-navigation {
    top: 57.719vw;
  }
}
</style>